<template>
  <v-row class="pb-8">
    <v-col
      cols="12"
      sm="12"
      class="pt-0"
    >
      <v-row>
        <v-col
          xs="12"
          sm="12"
          md="12"
          align-self="center"
        >
          <session-title
            class="pt-8 px-8 pb-0"
            title="Lista de Sugestões e Reclamações"
            description=""
          />
        </v-col>
        <v-col
          class="pt-0 px-11 pb-3"
          align-self="center"
        >
          <chat-ti-modal-access-vue />
        </v-col>
        <v-row>
          <v-col
            v-for="(item,i) in suggestion"
            :key="i"
            cols="3"
          >
            <v-card
              class="mx-auto"
              height="200"
              width="350"
              elevation="3"
            >
              <v-row>
                <v-card-title>
                  {{ item.type }}
                  <v-badge
                    v-if="item.status === 1"
                    class="badge"
                    dot
                  />
                </v-card-title>
                <v-card-title
                  v-if="item.user === 'Anônimo'"
                  id="tituloAnonimo"
                >
                  {{ item.user }}
                </v-card-title>
              </v-row>
              <v-row>
                <v-card-subtitle>{{ item.category }}</v-card-subtitle>
              </v-row>
              <v-row>
                <v-card-text>
                  <div v-if="item.text.length > 100">
                    {{ item.text.substring(0,100)+"..." }}
                  </div>
                  <div v-if="item.text.length < 100">
                    {{ item.text }}
                  </div>
                </v-card-text>
              </v-row>
              <v-card-actions>
                <v-btn
                  absolute
                  color="secondary"
                  right
                  bottom
                  route
                  :to="`/answerSuggestions/${item.id}`"
                >
                  Responder
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import SessionTitle from '@/components/SessionTitle'
import Suggestion from '../../services/suggestion-service'
import ChatTiModalAccessVue from "../../components/ModalViews/ChatTiModalAccess.vue";
import store from '../../store'

export default {
  name: 'SuggestionForm',
  components: {
    SessionTitle,
    ChatTiModalAccessVue
  },
  data () {
    return {
      loggedUser: '',
      suggestion: [],
      loading: false,
      items: ['Sugestão', 'Reclamação'],
      nameless: false
    }
  },
  async created() {
    this.getLoggedUser();
    await this.loadSuggestion();
  },
  methods: {
    async loadSuggestion() {
      const data = await Suggestion.getSuggestion(this.loggedUser);
      this.suggestion = data;
      console.log(this.suggestion);
    },

    async getLoggedUser(){
      this.loggedUser = store.getters['auth/userName']
    },
  },

}
</script>
<style >
  #btn-ler{
    align-items: left;
  }

  #tituloAnonimo{
    margin-top: 0;
  }
  .v-card__subtitle, .v-card__text, .v-card__title {
    padding: 0;
    padding-left: 10%;
  }
  .badge{
    padding-left: 3%;
  }
</style>
